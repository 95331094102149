/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./gull-admin/scripts');
require('cleave.js/dist/cleave-esm.min');
require('cropperjs/dist/cropper.esm');
require('jquery-autocomplete/jquery.autocomplete');
require('perfect-scrollbar');
require('quill/dist/quill.min');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'USR-TOKEN' : $('meta[name="usr-token"]').attr('content')
    }
});
